import React from "react"
import SEO from "../components/seo"
import { motion } from 'framer-motion'
import flyer from '../content/images/caww-flyer-2024.png'
// import { Link } from "gatsby"

const duration = 0.35

const container = {
  visible: {
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.2,
      delayChildren: duration,
    },
  },
}
const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
}

const IndexPage = () => {
  return (
    <>
      <SEO title="Home" />
      <motion.section
        variants={container}
        initial="hidden"
        animate="visible"
        className="container mx-auto p-4"
      >
        <div className="announcement pl-3 pb-5">
          <motion.div
            className="content"
            variants={item}
            transition="easeInOut"
          >
            <p className="title text-3xl">Apply to our Summer 2024 Workshop!</p>
          </motion.div>

          <motion.div
            className="content w-full"
            variants={item}
            transition="easeInOut"
          >
            <div className="description">
              <p className="text-md">
                We are now accepting applications for the <strong>Summer 2024 Short-form Fiction Workshop</strong> on a rolling basis. Tuition is free, courtesy of Stories Matter Foundation.
              </p>

              <p>
                To apply, please visit the following link:
                <ul className="pl-5">
                  <li>
                    <a
                      className="hover:text-yellow-500"
                      target="_blank"
                      href="https://forms.gle/n8sJU1YTmncJwut77"
                    >
                      <u>Fiction</u>
                    </a>
                  </li>
                </ul>
              </p>

              <div className="details-1">
                <p id="fiction-title" className="text-2xl">
                  <strong>Class:</strong> Short-form Fiction
                </p>
                <p className="text-md">
                  <strong>Instructor:</strong> Vinh Hoang is a Vietnamese-American writer and filmmaker from Houston, Texas with an MFA in fiction from the University of Mississippi. His work is published and forthcoming in Defunkt Magazine, Glass Mountain, The South Dakota Review, and others.
                </p>

                <p className="text-md">
                  <strong>Date:</strong> 6:30-8:00 PM Wednesdays, September 4 - September 25
                </p>
                <p className="text-md">
                  <strong>Location:</strong> 4043 N Ravenswood Ave, Chicago, IL 60613
                </p>

                <p className="text-md">
                  <strong>Course description:</strong> Throughout this four week session, we will explore some examples of Asian American short fiction. What can short fiction do for our stories? And what are its limitations? Everyone will share a short fiction project for consideration and discussion. The goal of this workshop will be to make new connections between art and artists and explore the relationship between the form and our identity.
                </p>
                <div className="flex justify-center my-4">
                  <img src={flyer} alt="caww flyer" className="w-1/2" />
                </div>
                <p className="flex justify-center my-4 text-md">
                  <a
                    className="hover:text-yellow-500"
                    target="_blank"
                    href="https://forms.gle/FLLgrrGiFR58rY9h9"
                  >
                    <u>Apply here</u>.
                  </a>
                </p>
              </div>

              <p className="text-md">
                A writers' workshop is a space in which writers come together to praise, critique, and review each others' work.
                <br /><br />
                This session's workshop will take place IN PERSON at{' '}
                <a
                  className="hover:text-yellow-500"
                  href="https://www.storystudiochicago.org/"
                >
                  <u>StoryStudio Chicago</u>
                </a>{' '}
                in Ravenswood.
                <br /><br />
                Each workshop will last no longer than 2 hours. Vaccination and proof of vaccination will be required to enter the building.
                <br /><br />
                StoryStudio Chicago's vaccination policy can be found{' '}
                <a
                  className="hover:text-yellow-500"
                  href="https://drive.google.com/file/d/16M_bseoJe66c9xtDUysZIEOIf0nTV4LU/view"
                >
                  <u>here</u>.
                </a>
              </p>
            </div>
          </motion.div>
        </div>
      </motion.section>
    </>
  );
};

export default IndexPage;
